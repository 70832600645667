import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import { API_BASE_URL } from '../constants';

function CourseList({ setThemeColor, setFont }) {
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    courseType: '',
    city: '',
  });
  const [translations, setTranslations] = useState(null);
  const [loading, setLoading] = useState(false);

  // State for mobile detection
  const [isMobile, setIsMobile] = useState(false);

  const [showPrice, setShowPrice] = useState(false);
  const [showSlots, setShowSlots] = useState(false);

  // State for error messages
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const iframeKey = params.get('iframeKey' || '');

    const courseType = params.get('courseType') || '';
    const city = params.get('city') || '';
    setFilters({ courseType, city });

    // Fetch settings and courses
    fetchSettings(iframeKey);
    fetchCourses(iframeKey, courseType, city);

    // Check for mobile on component mount
    checkForMobile();
    window.addEventListener('resize', checkForMobile);

    return () => {
      // Cleanup on component unmount
      window.removeEventListener('resize', checkForMobile);
    };
  }, []);

  async function fetchSettings(iframeKey) {
    try {
      const response = await fetch(`${API_BASE_URL}/iframe/settings?iframekey=${iframeKey}`);
      const data = await response.json();

      if (data.isSuccessful) {
        setThemeColor(data.settings.iframeCourseBookingColor);
        setFont(data.settings.iframeCourseBookingFont);
        setCourseTypes(data.filterOptions.courseTypes.sort((a, b) => a.localeCompare(b)));
        setCities(data.filterOptions.cities.sort((a, b) => a.localeCompare(b)));
        setShowPrice(String(data.settings.iframeShowPrice).toLowerCase() == "true")
        setShowSlots(String(data.settings.iframeShowSlots).toLowerCase() == "true")
        setTranslations({
          CourseType: data.translations.CourseType || 'Course Type',
          City: data.translations.City || 'City',
          AllLabel: data.translations.AllLabel || 'All',
          Courses: data.translations.Courses || 'Courses',
          ApplyFilters: data.translations.ApplyFilters || 'Apply Filters',
          CourseDescription: data.translations.CourseDescription || 'Course Description',
          ErrorLoading: data.translations.ErrorLoading || 'Error loading data.',
          Search: data.translations.Search || 'Search',
          StartDate: data.translations.StartDate || 'Start Date',
          EndDate: data.translations.EndDate || 'End Date',
          NoCoursesFound: data.translations.NoCoursesFound || 'No courses found.',
          PickCourse: data.translations.PickCourse || 'Pick a course',
          Price: data.translations.Price || 'Price',
          BookedSeats: data.translations.BookedSeats || 'Booked Seats',
          Name: data.translations.Name || 'Name',
          OrgNo: data.translations.OrgNo || 'OrgNo',
          PurchaseOrderNumber: data.translations.PurchaseOrderNumber || 'Purchase order number',
          ExtraMessage: data.translations.ExtraMessage || 'Extra Message',
          ContactPerson: data.translations.ContactPerson || 'Contact Person',
          Email: data.translations.Email || 'Email',
          Phone: data.translations.PhoneNbr || 'Phone number',
          Company: data.translations.Company || 'Company',
          OrderInformation: data.translations.OrderInformation || 'Order information',
          BookingInformation: data.translations.BookingInformation || 'Booking information',
          Address: data.translations.Address || 'Address',
          ZipCode: data.translations.ZipCode || "Zip code",
          NoParticipants: data.translations.NoParticipants || "Number of participants",
          ActorType_Student: data.translations.ActorType_Student || "Course participants",
          SocialSecurityNumber: data.translations.SocialSecurityNumber || 'Social Security Number',
          CompanyInfo: data.translations.CompanyInfo || 'Company Info',
          Submit: data.translations.Submit || "Submit",
          GDPRtext: data.translations.GDPRtext || "I consent to data processing",
          Next: data.translations.Next || "Next",
          Back: data.translations.Back || "Back",
          Address2: data.translations.Address2 || "Address",
          ThanksBookingRequest: data.translations.ThanksBookingRequest || "Thank you for your booking request",
          EmailConfirmation: data.translations.EmailConfirmation || "You will receive a confirmation email shortly",
          PDFInvoiceAddress: data.translations.PDFInvoiceAddress || "PDF invoice address",
          Course: data.translations.Course || "Course",
          BookingRequest: data.translations.BookingRequest || "Booking request",
          InvalidTurnstileToken: data.translations.InvalidTurnstileToken || "Invalid Turnstile token",
          BackToCourseList: data.translations.BackToCourseList || "Back to the course list",
        });
      } else {
        console.error('Failed to fetch settings:', data.errorMessageTranslationKey);
        setErrorMessage('Failed to fetch settings.');
        setShowError(true);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setErrorMessage('Error fetching settings.');
      setShowError(true);
    }
  };

  async function fetchCourses(iframeKey, courseType, city) {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams({});

      if (iframeKey) {
        queryParams.append('iframeKey', iframeKey);
      }

      if (courseType) {
        queryParams.append('courseType', courseType);
      }
      if (city) {
        queryParams.append('city', city);
      }
      const response = await fetch(`${API_BASE_URL}/iframe/courses?${queryParams}`);
      const data = await response.json();

      const sortedCourses = data.courses.sort((a, b) => new Date(a.fromDate) - new Date(b.fromDate));
      setCourses(sortedCourses);

    } catch (error) {
      console.error('Error fetching courses:', error);
      setErrorMessage('Error fetching courses.');
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  function handleFilterChange(e) {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  function applyFilters() {
    const iframeKey = getQueryParameterByName("iframeKey");

    const params = new URLSearchParams();

    params.append('iframeKey', iframeKey)

    if (filters.courseType) {
      params.append('courseType', filters.courseType);
    }
    if (filters.city) {
      params.append('city', filters.city);
    }
    navigate(`/?${params.toString()}`);
    fetchCourses(iframeKey, filters.courseType, filters.city);
  };


  function getQueryParameterByName(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  function checkForMobile() {
    const mobileWidth = 760;
    setIsMobile(window.innerWidth <= mobileWidth);
  }

  function formatToSEK(number) {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
    }).format(number);
  }

  function renderTable(isMobile, courses) {
    if (isMobile) {
      return (
        <Table variant="outlined">
          <TableHead>
            <TableRow>
              <TableCell><b>{translations.Courses}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow
                key={course.courseId}
                hover
                onClick={() =>
                  navigate(`/book/${course.courseId}?iframeKey=${getQueryParameterByName("iframeKey")}`, {
                    state: {
                      translations: translations,
                      course: course
                    }
                  })}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <b>{course.licenseDescription}</b> <br />
                  {course.city} <br />
                  {new Date(course.fromDate).toLocaleDateString()} - {new Date(course.toDate).toLocaleDateString()}
                  {showSlots && (
                    <>
                      <br />
                      {translations.BookedSeats}: {course.bookedCourseParticipants}/{course.maxCourseParticipants}
                    </>
                  )}

                  {showPrice && (
                    <>
                      <br />
                      {translations.Price}: {formatToSEK(course.price)}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    } else {
      return (
        <Table variant="outlined">
          <TableHead>
            <TableRow>
              <TableCell><b>{translations.CourseType}</b></TableCell>
              <TableCell><b>{translations.City}</b></TableCell>
              <TableCell><b>{translations.StartDate}</b></TableCell>
              <TableCell><b>{translations.EndDate}</b></TableCell>
              {showSlots && <TableCell><b>{translations.BookedSeats}</b></TableCell>}
              {showPrice && <TableCell><b>{translations.Price}</b></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow
                key={course.courseId}
                hover
                onClick={() =>
                  navigate(`/book/${course.courseId}?iframeKey=${getQueryParameterByName("iframeKey")}`, {
                    state: {
                      translations: translations,
                      course: course
                    }
                  })}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{course.licenseDescription}</TableCell>
                <TableCell>{course.city}</TableCell>
                <TableCell>{new Date(course.fromDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(course.toDate).toLocaleDateString()}</TableCell>
                {showSlots && <TableCell>{course.bookedCourseParticipants}/{course.maxCourseParticipants}</TableCell>}
                {showPrice && <TableCell>{formatToSEK(course.price)}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
  }

  if (translations === null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>)
  }

  return (
    <Container>
      <h2>{translations.PickCourse}</h2>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel shrink={true}>{translations.CourseType}</InputLabel>
            <Select
              name="courseType"
              value={filters.courseType}
              onChange={handleFilterChange}
              label={translations.CourseType}
              displayEmpty
              notched={true}
            >
              <MenuItem value="">
                {translations.AllLabel}
              </MenuItem>
              {courseTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel shrink={true}>{translations.City}</InputLabel>
            <Select
              name="city"
              value={filters.city}
              onChange={handleFilterChange}
              label={translations.City}
              displayEmpty
              notched={true}
            >
              <MenuItem value="">
                {translations.AllLabel}
              </MenuItem>
              {cities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={applyFilters} size="medium">
            {translations.Search}
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {courses.length === 0 && !loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <p>{translations.NoCoursesFound}</p>
            </Box>
          )}
          {courses.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4, mb: 4 }} elevation={0} variant="outlined">
              {renderTable(isMobile, courses)}
            </TableContainer>
          )}
        </>
      )}
      {/* Error Snackbar */}
      <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CourseList;